.v3-left-menu {
    font-size: 1.5em;
}

.v3-left-menu-item {
    margin: 0.5em;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5em;
}

.v3-left-menu-item-selected {
    border-style: solid;
    border-color: rgb(233, 38, 194);
    border-radius: 0.5em;
    background-color: rgb(43, 41, 41);
}