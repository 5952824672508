.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.b1 {
  margin: 4px;
  padding: 4px;
}

.marketing-up {
  background-color: rgb(255, 255, 255);
  padding-top: 32px;
  padding-left: 128px;
  padding-right: 128px;
  padding-bottom: 32px;
  /* padding-bottom: 256px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.learn-more-button {
  margin-top: 64px;
}

.marketing-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.login-div {
  padding: 16px;
  margin: 16px;
  text-align: center;
  background-color: rgb(246, 246, 255);
  border-radius: 16px;
  width: max-content;
  height: max-content;
  /* height: 200px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.cr {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.marketing-logo {
  margin-right: 64px
}

.main-title {
  text-align: center;
}

.logo {
  font-size: 64px;
  color: green;
  font-weight: bold;
  text-align: left;
}

.sub-title {
  font-size: 32px;
  color: rgb(80, 66, 66);
}

.marketing-down {
  text-align: center;
  color: green;
  padding: 8px;
  margin-top: 16px;
  font-size: 20px;
}

.login-pending-spinner {
  padding: 16px;
}

.login-error-content {
  color: red;
  margin: 32px;
}

.marketing-learn-more {
  margin: 8px;
  padding: 8px;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: max-content;
  border-radius: 8px;
  background-color: rgb(242, 228, 255);
  color: rgb(73, 73, 73);
}

.marketing-lm-container {
  margin-top: 128px;
}

.marketing-vms-container {
  margin-left: 32px;
  margin-right: 32px;
}

.marketing-vms {
  margin: 32px;
}

.one-to-zero {
  padding: 16px;
  background-image: linear-gradient(rgb(195, 255, 195), rgb(255, 255, 255));
}

.h2 {
  color: rgb(76, 76, 76);
}

.exp-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.exp-content {
  text-align: justify;
  font-size: 24px;
  color: grey;
  margin: 32px;
}