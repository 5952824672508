.demo-parent {
    padding: 3.em;
    font-size: 0.8em;
}

.demo-step-wait-to-start {
    padding: 3em;
}

.demo-step-ack-role {
    padding: 3em;
}

.demo-step-ack-role-scenario {
    background-color: rgb(197, 255, 109);
    width: 15em;
    height: 10em;
    cursor: pointer;
    padding-top: 4em;
    color: rgb(59, 59, 59);
}

.demo-step-ack-role-scenario:hover {
    background-color: rgb(217, 252, 165);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.demo-step-wait-to-start-content {
    padding: 3em;
}

.demo-start-button {
    background-color: greenyellow;
    width: max-content;
    padding: 1em;
    cursor: pointer;
    color: rgb(59, 59, 59);
}

.demo-start-button:hover {
    background-color: rgb(199, 253, 117);
}

.demo-step-choose-stack {
    padding: 3em;
}

.demo-step-choose-stack-tech {
    width: 5em;
    padding: 1em;
    margin: 0.1em;
    background-color: aquamarine;
    cursor: pointer;
}

.demo-step-choose-stack-tech:hover {
    background-color: rgb(176, 248, 224);
}

.demo-step-choose-stack-collumn {
    background-color: rgb(223, 255, 244);
    padding: 1.8em;
    margin: 1.2em;
    /* width: 12em; */
    width: max-content;
}

.demo-step-review {
    padding: 3em;
}

.demo-step-review-todos {
    background-color: rgb(255, 198, 198);
    padding: 1.5em;
    margin: 1.5em;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.demo-step-review-todos-green {
    background-color: rgb(205, 255, 198);
    padding: 1.5em;
    margin: 1.5em;
    border-radius: 2em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    animation: pulse 2s infinite;
}

.demo-step-details {
    padding: 3em;
    font-size: 0.9em;
}

.demo-details-cloud {
    background-color: aqua;
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-vm {
    background-color: fuchsia;
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-services {
    background-color: azure;
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-vpn {
    margin-top: 4em;
    background-color: rgb(209, 255, 216);
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: max-content;
    border-radius: 0.2em;
}

.demo-details-ssh {
    background-color: rgb(147, 250, 137);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 1.5em;
}

.demo-details-screen-share {
    background-color: rgb(248, 207, 255);
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: max-content;
    border-radius: 0.2em;
}

.demo-details-pc {
    background-color: rgb(178, 253, 92);
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-ide {
    background-color: fuchsia;
    padding: 1.5em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-comp {
    padding: 1.5em;
}

.demo-details-you {
    background-color: rgb(255, 255, 0);
    padding: 2em;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.demo-details-new-colleague {
    margin-top: 4em;
    background-color: rgb(156, 204, 246);
    border-radius: 50%;
    padding: 2em;
}

.demo-pulse {
    animation: pulse 2s infinite;
}