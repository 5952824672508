.project-invite-cover {
    padding: 2em;
    background-color: aquamarine;
    margin: 1em;
}

.project-invite-cover:hover {
    background-color: rgb(51, 230, 170);
}

.pic-tab {
    padding: 1em;
}

.pic-item {
    padding: 1em;
    margin-left: 2em;
}