.notification {
    background-color: rgb(255, 175, 255);
    color: grey;
    padding: 1em;
    margin: 1em;
    cursor: pointer;
}

.notification:hover {
    background-color: rgb(253, 130, 253);
}