.h2-style {
    color: grey;
}

.h4-style {
    color: grey;
}

.contact {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 32px;
    margin: 32px;
    width: max-content;
}

.contact-item {
    width: 100%;
}

.contact-item-container {
    padding: 8px;
    margin: 8px;
}

.contact-loading {
    margin: 64px;
    padding: 64px;
}

.contact-done-logo {
    color: green;
    padding: 16px;
}