.v3-project-menu {
    font-size: 1.5em;
    display: flex;
}

.v3-project-menu-item {
    /* padding: 1em; */
    margin-right: 1em;
    cursor: pointer;
}

.v3-project-menu-item-selected {
    font-weight: bold;
    color: greenyellow;
}