.col {
  margin: 1em;
}

.my-t {
  border-style: solid;
  border-color: rgb(199, 17, 227);
  border-radius: 0.5em;
  padding: 1em;
  margin: 1em;
}

.main-diff {
  background-color: black;
  color: white;
}