.v2-menu {
    background-color: rgba(229, 255, 246, 255);
}

.v2-menu-item {
    background-color: rgba(4, 67, 100, 255);
    color: rgba(229, 255, 246, 255);
    font-size: 1.4em;
    font-weight: 300;
    padding: 0.8em;
    cursor: pointer;
}

.v2-menu-item:hover {
    background-color: rgba(109, 155, 146, 155);
}

.v2-menu-item-left {
    border-bottom-left-radius: 1em;
}

.v2-menu-item-right {
    border-bottom-right-radius: 1em;
}

.v2-main {
    background-color: rgba(229, 255, 246, 255);
    padding: 4em;
    color: rgba(4, 67, 100, 255);
}

.v2-logo {
    font-weight: 800;
    font-size: 2em;
    padding: 0.5em;
}

.v2-logo-sub {
    font-size: 1.5em;
    padding: 0.5em;
}

.v2-gold-container {
    position: relative;
    text-align: center;
    color: white;
    cursor: pointer;
    padding: 0.5em;
}

.v2-gold-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(4, 67, 100, 255);
    font-size: 1em;
}

.v2-why {
    background-color: white;
    color: rgba(4, 67, 100, 255);
    font-size: 24px;
    width: 32em;
    padding: 2em;
    transition: transform .2s;
}

.v2-why:hover {
    transform: scale(1.2);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    border-radius: 1em;
}

.v2-here-to-help {
    padding: 2em;
    background-color: rgba(229, 255, 246, 255);
    color: rgba(4, 67, 100, 255);
    font-size: 24px;
}

.round1 {
    background-color: rgba(229, 255, 246, 255);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    padding: 4em;
}

.round2 {
    background-color: rgba(229, 255, 246, 255);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    padding: 4em;
}

.video1 {
    margin: 2em;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
    /* padding: 1em; */
    border-radius: 0.5em;
    transition: transform .2s;
}

.video1:hover {
    transform: scale(1.5);
    margin-right: 2em;
}

.video-see-more {
    cursor: pointer;
}