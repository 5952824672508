.templates {
    padding-left: 2em;
    /* background-color: rgb(210, 255, 143); */
    display: flex;
}

.templates-left {
    padding-top: 1em;
    display: flex;
    flex-wrap: wrap;
}

.templates-right-menu {
    position: fixed;
    right: 0;
    padding: '0.5em';
    margin: '0.5em';
    background-color: rgba(229, 255, 246, 255);
    height: 100%;
    padding: 2em;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}