.intern-main {
    margin: 1em;
}

.chart1 {
    width: 50em;
    margin: 1em;
    padding: 1em;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    border-radius: 0.5em;
}

.intern-object {
    padding: 2em;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    width: 30em;
    cursor: pointer;
}

.intern-objects {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.ldc {
    padding: 2em;
}

.ld {
    margin: 2em;
    padding: 4em;
}

.ld1 {
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    background-color: rgb(59, 67, 207);
    padding: 4em;
}

.ld2 {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    background: transparent;
    padding: 3em;
    padding-bottom: 1em;
    margin-top: -5em;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ldm {
    background-color: aqua;
    padding: 1em;
    margin-top: -5em;
    margin-left: 2em;
    height: 10em;
    width: 10em;
}

.ld2-ctas {
    margin-top: 1em;
    color: grey;
    font-weight: bold;
    font-size: 1.2em;
}