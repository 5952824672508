.project-cover-main {
    padding: 2em;
    margin: 0.5em;
    /* background-color: rgb(210, 255, 143); */
    /* background-color: rgba(4, 67, 100, 255); */
    background-color: rgba(229, 255, 246, 255);
    width: 12em;
    height: 15em;
    cursor: pointer;
    border-radius: 0.2em;
    color: grey;
    transition: transform .4s;

    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.project-cover-main:hover {
    background-color: rgba(229, 255, 256, 255);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transform: scale(1.1);
}

.cut-text {
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}