.dc-main-container {
    text-align: center;
    padding: 32px;
}

.dc-main-div {
    text-align: center;
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 8px;
}

.dc-main-table {
    text-align: center;
}

.dc-title {
    padding: 8px;
    font-weight: bold;
    font-size: 24px;
    color: grey;
}

.dc-item {
    padding: 16px;
}

.dc-item-button {
    padding: 16px;
}

.dc-item-left {
    padding: 8px;
    text-align: left;
}

.dc-item-title {
    font-weight: bold;
    font-size: 18px;
    padding: 8px;
}

.dc-dialog {
    text-align: center;
}

.service-label {
    padding: 4px;
}

.services-item {
    padding: 4px;
    cursor: pointer;
}

.dc-create-error {
    align-items: left;
    color: red;
}