.devservers-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.devservers-empty-state {
    padding: 64px;
    font-size: 24px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: max-content;
}

.dev-container {
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.devs-left {
    background-color: rgba(229, 255, 246, 255);
    justify-content: center;
    width: 50%;
    position: fixed;
    left: 18%;
}

.devs-right {
    background-color: rgba(229, 255, 246, 255);
    padding: 2em;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    position: fixed;
    right: 0;
    height: 100%;
}