.div-inputs {
    padding: 16px;
    text-align: center;
}

.div-create-button {
    text-align: center;
    margin-top: 16px;
}

.login-input {
    margin: 8px;
}

.div-spinner {
    text-align: center;
    padding: 16px;
    margin: 32px;
}

.div-error-content {
    color: red;
    margin-top: 32px;
}

.div-error-button {
    text-align: center;
    padding: 32px;
}

.div-success-content {
    color: green;
    margin-top: 32px;
}

.div-success-button {
    text-align: center;
    padding: 32px;
}