.projects-main-left {
    padding: 2em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-items: center;
    /* width: 100%; */
    position: fixed;
    left: 8%;
}

.projects-main-right {
    position: fixed;
    right: 0;
    height: 100%;
    background-color: rgba(229, 255, 246, 255);
    padding: 1em;
    color: rgb(36, 36, 36);
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
