.project-details {
    /* background-color: rgb(238, 255, 212); */
    background-color: rgba(229, 255, 246, 255);
    margin: 2em;
    padding: 0.5em;
    color: rgb(73, 73, 73);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.project-details-tab {
    padding: 1em;
    background-color: rgb(255, 255, 255);
    margin: 0.2em;
    max-width: 20em;
    /* white-space: initial; */
}

.project-details-cta {
    margin: 0.6em;
    /* background-color: rgb(152, 230, 50); */
    padding: 0.5em;
    color: grey;
    cursor: pointer;
    font-size: 0.7em;
}

.project-details-cta:hover {
    /* background-color: rgb(164, 245, 58); */
    /* background-color: rgba(4, 67, 100, 255); */
    background-color: rgba(229, 255, 246, 255);
}