.v3-card-with-point {
    width: 70em;
    position: absolute;
    left: 21.3%;
    top: 90%;
}

.v3-card-with-point-title {
    display: flex;
    justify-content: left;
    align-items: center;
}

.v3-text-green {
    color: #3CE342;
}

.v3-card-with-point-p {
    height: 32px;
    width: 32px;
    border-radius: 2em;
    margin-right: 1em;
    margin-bottom: 1em;
    border-style: solid;
    border-color: black;
    border-width: 0.3em;
}

.pink {
    background-color: rgb(233, 38, 194);
}