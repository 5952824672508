.v3-feed {
    background-color: black;
    color: white;
}

.v3-feed-main {
    display: flex;
    justify-content: space-between;
}

.v3-feed-main-item-left {
    margin-right: 1em;
    padding: 5em;
    background-color: rgb(31, 29, 29);
    border-top-right-radius: 1em;
    height: auto;
}

.v3-feed-main-item-center {
    margin-left: 1em;
    margin-right: 1em;
    padding: 5em;
    background-color: rgb(31, 29, 29);
    width: 100%;
    border-style: solid;
    border-color: rgb(233, 38, 194);
    border-radius: 1em;
    margin: 1em;
}

.v3-feed-main-item-right {
    border-top-left-radius: 1em;
    margin-left: 1em;
    padding: 5em;
    background-color: rgb(31, 29, 29);
    height: 1000vh;
}

.v3-input {
    background-color: rgb(64, 57, 57);
    color: white;
    font-size: 1.2em;
    padding: 0.5em;
    border-color: black;
    border-style: solid;
}

.v3-dialog-header {
    background-image: none;
    background-color: black;
    color: white;
    border-color: red;
    border-style: solid;
    border-bottom: 0;
}

.v3-dialog-content {
    background-image: none;
    background-color: black;
    color: white;
    border-color: red;
    border-style: solid;
    border-top: 0;
}