.v3-card {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.8em;
    cursor: pointer;
    height: 6em;
    width: 6em;
    margin: 1em;
    border-radius: 0.5em;
}

.v3-card-c {
    height: 6em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    /* margin: 1em; */
    border-style: solid;
    border-radius: 0.5em;
}

.v3-card-c-pink {
    border-color: rgb(233, 38, 194);
}

.v3-card-c-blue {
    border-color: #0094FF;
}