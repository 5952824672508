.line-green {
    background-color: green;
    color: white;
}

.line-red {
    color: white;
    background-color: red;
    opacity: 0.4;
}

.my-tr {
    font-size: 1.1em;
    /* padding: 0.1em; */
    border: none;
}

.my-td {
    border: none;
    font-family: monospace;
    white-space: initial;
}

.my-cell {
    width: 40vw;
    overflow: hidden;
    font-size: 1.1em;
    margin-top: -0.5em;
    margin-bottom: -0.5em;
}