.comp-create {
    margin: 32px;
    padding: 32px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.comp-create-button-create {
    padding: 16px;
}

.comp-create-title {
    color: grey;
    font-size: 20px;
    font-weight: bold;
}

.comp-create-p {
    color: grey;
    padding: 8px;
}

.comp-create-input-name {
    padding: 16px;
}

.comp-create-warning-icon {
    color: rgb(192, 119, 10);
}