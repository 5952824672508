.v2-case {
    padding: 2em;
    width: 40em;
}

.v2-case-image {
    padding: 1em;
}

.v2-case-content {
    padding: 2em;
    padding-left: 4em;
}

.v2-case-content-text {
    color: grey;
    font-weight: 400;
}