.directory {
    padding: 32px;
}

.directory-user {
    padding: 32px;
    margin: 16px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    color: grey;
}

.dir-item {
    margin: 8px;
}