.v3-button {
    color: #0094FF;
    margin: 1em;
    margin-left: 0;
    font-weight: bold;
    cursor: pointer;
    border-color: #0094FF;
    border-style: solid;
    border-radius: 0.2em;
    width: max-content;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
}



.v3-button:hover {
    color: #81caff;
    border-color: #81caff;
}

.v3-button-danger {
    color: rgb(246, 77, 77);
    border-color: rgb(246, 77, 77);
}

.v3-button-danger:hover {
    color: rgb(247, 52, 52);
    border-color: rgb(247, 52, 52);
}

.v3-button-success {
    color: rgb(116, 251, 75);
    border-color: rgb(89, 226, 34);
}

.v3-button-success:hover {
    color: rgb(61, 208, 16);
    border-color: rgb(44, 137, 7);
}

.v3-button-disabled {
    color: #043a62;
    border-color: #043a62;
}

.v3-button-disabled:hover {
    color: #043a62;
    border-color: #043a62;
    cursor: not-allowed;
}