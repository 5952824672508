.exp-container {
    margin: 64px;
    margin-bottom: 64px;
    padding-bottom: 32px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 16px;
}

.exp-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    filter: brightness(90%);
}

.exp-content {
    text-align: justify;
    font-size: 24px;
    color: grey;
    margin: 32px;
}

.exp-quote {
    font-style: italic;
    font-size: 28px;
    color: grey;
    margin: 32px;
}