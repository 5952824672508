.footer-logo {
    color: green;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.footer-user {
    color: grey;
    margin-left: 16px;
    cursor: pointer;
}