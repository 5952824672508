.template-cover {
    background-color: rgba(229, 255, 246, 255);
    padding: 1em;
    margin: 0.5em;
    color: rgb(85, 84, 84);
    /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    width: 32em;
}

.template-cover-service {
    background-color:rgb(134, 249, 249);
    margin: 0.1em;
    padding: 1em;
}