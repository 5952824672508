.main-div {
    text-align: center;
    background-color: rgb(203, 223, 250);
}

.item {
    padding: 32px;
}

.menu-item {
    margin: 16px;
    font-size: 24px;
    cursor: pointer;
}

.menu-item-small {
    margin: 16px;
    margin-left: 32px;
    font-size: 16px;
    cursor: pointer;
    color: rgb(67, 67, 67);
    font-weight: bold;
}

.menu-item-small-disabled {
    margin: 16px;
    margin-left: 32px;
    font-size: 16px;
    cursor: pointer;
    color: grey;
}

.line {
    padding: 1px;
    background-color: grey;
}

.footer {
    background-color: rgba(229, 255, 246, 255);
    padding: 32px;
}

.main-menu {
    font-weight: bold;
    /* background-color: rgb(67, 67, 67); */
    background-color: rgba(4, 67, 100, 255);
    color: white;
    padding: 24px;
}

.main-menu-comp {
    font-weight: bold;
    background-color: rgb(113, 179, 7);
    color: white;
    padding: 24px;
}

.main-menu-item {
    cursor: pointer;
}