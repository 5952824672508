.devserver {
    border-radius: 0.5em;
    width: max-content;
    margin: 8px;
    font-size: 0.8em;
}

.devserver:hover {
    /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

.title {
    font-size: 1.4em;
    font-weight: bold;
    margin: 16px;
}

.buttons {
    padding: 16px;
    text-align: center;
}

.btn {
    padding: 4px;
}

.services {
    background-color: rgb(236, 255, 231);
    padding: 4px;
    margin: 8px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.services-title {
    font-weight: bold;
    margin: 4px;
}

.services-list {
    text-align: left;
    margin-right: 16px;
}

.devserver-ready {
    color: green;
    font-weight: bold;
}

.creating-spinner-div {
    padding: 16px;
}

.create-howto-image-div {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
}

.create-howto {
    text-align: center;
    color: grey;
    font-weight: bold;
    font-size: 22px;
}

.create-howto-step-title {
    padding: 4px;
}

.setup-tasks {
    padding: 16px;
    color: grey;
}

.create-now {
    font-size: 20px
}

.create-not-ready {
    padding: 32px;
}

.create-see-console {
    color: blue;
    cursor: pointer;
}

.ca-manually-trust {
    cursor: pointer;
    color: blue;
    margin: 4px;
}