.v3 {
    /* position: fixed; */
    /* top: 0; */
    width: 100%;
}

.v3-menu {
    padding: 0.1em;
    border-radius: 0.4em;
    background-image: linear-gradient(to right, rgb(233, 38, 194, 0.5), rgb(0, 64, 255, 0.5));
}

.v3-menu-items-container {
    display: flex;
    margin-right: 2em;
    width: 100%;
    justify-content: right;
}

.v3-menu-item {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    padding: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    cursor: pointer;
}

.v3-menu-item:hover {
    font-weight: 900
}

.v3-menu-logo {
    /* color: #0094FF; */
    color: rgb(0, 148, 255, 0.5);
    font-weight: 900;
    font-size: 1.6em;
    margin-left: 1em;
    cursor: pointer;
}

.v3-header-title {
    color: white;
    font-size: 2em;
    font-weight: bold;
}

.v3-header-subtitle {
    color: #0094FF;
    font-weight: bold;
    margin-top: 1.2em;
}

.v3-header-body {
    margin-top: 1em;
    color: white;
}

.v3-header-demo-button {
    color: #0094FF;
    margin: 1em;
    margin-left: 0;
    font-weight: bold;
    cursor: pointer;
    border-color: #0094FF;
    border-style: solid;
    border-radius: 0.2em;
    width: max-content;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
}

.v3-header-demo-button:hover {
    color: #81caff;
    border-color: #81caff;
}

.v3-bg {
    background-color: rgb(0, 0, 0, 0.8);
    padding: 0.5em;
}

.v3-down {
    background-color: black;
    /* height: 200em; */
    width: 100%;
    color: white;
    padding-top: 4em;
    padding-bottom: 12em;
}

.v3-footer {
    color: #81caff;
    font-size: 1.2em;
}

.v3-footer-line {
    margin-top: 1em;
}

.v3-card-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4em;
}

.v3-line {
    left: 25%;
    width: 50%;
    position: absolute;
    /* top: 8em; */
    top: 22%;
    height: 60%;
    /* transform: translate(0, -50%); */
    border-radius: 3em;
    border-style: solid;
    border-color: rgb(233, 38, 194);
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.v3-line-b {
    left: 22%;
    width: 50%;
    position: absolute;
    /* top: 8em; */
    top: 72.7%;
    height: 58.3%;
    /* transform: translate(0, -50%); */
    border-radius: 3em;
    border-style: solid;
    border-color: rgb(233, 38, 194);
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.v3-line-text {
    position: absolute;
    top: 17%;
    left: 27%;
    /* transform: translate(0, -50%); */
}

.v3-line-text-right {
    position: absolute;
    top: 17%;
    left: 20%;
    /* transform: translate(0, -50%); */
}