.project-add-template {
    padding: 2em;
}

.project-add-template-line {
    padding: 1em;
    margin: 1em;
    background-color: rgb(210, 255, 143);
    width: auto;
    cursor: pointer;
}


.project-add-template-line:hover {
    background-color: rgb(232, 253, 199);
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}